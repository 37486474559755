@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
* {
  font-family: 'Poppins', sans-serif;
}

.page-wrapper {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  flex-direction: column;
  width: 90%;
  font-weight: 500;
  margin: 20px auto;
  min-width: 0;
}

.content-wrapper {
  width: 100%;
  font-weight: 500;
  margin: 20px auto;
  min-width: 0;
}

.filters-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  min-width: 0;
  grid-gap: 8px;
  gap: 8px;
  width: 100%;
}

.button-group {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 25px;
  margin-bottom: 25px !important;
}

.atualizar {
  align-self: flex-end;
}

.download {
  align-self: flex-start;
}

/* #components-layout-demo-top-side-2 .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}

.ant-row-rtl #components-layout-demo-top-side-2 .logo {
  float: right;
  margin: 16px 0 16px 24px;
} */

.MenuItem {
  text-decoration: none;
  color: black;
  border-color: white;
  background-color: white;
}

.ant-menu-item-active {
  background-color: white !important;
}
.ant-menu-item-selected {
  color: black;
  background-color: white !important;
}

img {
  width: 189.3px;
  height: 47.4px;
  margin-left: -40px;
}

h6 {
  font-weight: 600;
  margin-top: 4px;
  font-size: 20px;
}

.ant-descriptions {
  min-width: 1000px;
}

.ant-table-thead {
  font-weight: 700 !important;
}

.colunas {
  font-weight: 600;
}

.ant-btn {
  margin-left: 0px;
}

.anticon-sync > svg:nth-child(1) {
  margin-bottom: 6px;
}

.alert-billing {
  margin-top: 20px;
}
.card {
  margin-top: 100px;
  text-align: center;
  width: 30%;
  background-color: rgb(255, 255, 255);
  margin-left: -200px;
  border-color: rgb(253, 253, 253);
}

h6 {
  font-weight: 600;
  margin-top: 4px;
  font-size: 20px;
}

.ant-descriptions {
  min-width: 1000px;
}

.ant-table-thead {
  font-weight: 700 !important;
}

.colunas {
  font-weight: 600;
}

.ant-btn {
  margin-left: 0px;
}

.anticon-sync > svg:nth-child(1) {
  margin-bottom: 6px;
}

.anticon-loading > svg:nth-child(1) {
  margin-bottom: 7px;
}

.login {
  width: 100%;
  min-height: 100vh;
  padding: 0 20px;
  background: #e2e2e2;
  display: flex;
}

.login-container {
  padding: 60px;
  margin: auto;
  width: 100%;
  max-width: 520px;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
}

/* background: radial-gradient(
  ellipse at left bottom,
  rgba(9, 60, 105, 1) 0%,
  rgba(25, 56, 130, 0.9) 59%,
  rgba(20, 63, 133, 0.9) 100%
); COR ANTERIOR DO LOGIN-CONTAINER
box-shadow: 0 50px 70px -20px rgba(0, 0, 0, 0.8); 
*/

.login-btn {
  border: none;
  outline: none;
  width: 100%;
  padding: 15px 0;
  color: #fff;
  font-size: 16px;
  letter-spacing: 1px;
  background: #0e6ab9;
  cursor: pointer;
  transition: all 0.2s;
}

.login-btn:hover {
  color: #2e2e2e;
  background: #fcca37;
}

.login-title {
  text-align: center;
  padding-bottom: 10%;
  color: white;
  line-height: 1;
  font-size: 30px;
}

.login-label {
  color: rgb(0, 0, 0);
  margin: 14px 0;
  display: block;
  font-size: 22px;
  line-height: 1;
}

.login-input {
  width: 100%;
  border: none;
  outline: none;
  font-size: 19px;
  padding: 10px;
  background: rgba(101, 158, 224, 0.1);
  color: rgb(0, 0, 0);
  letter-spacing: 1px;
}

.login-btn-container {
  width: 100%;
  padding: 24px 0;
}

.login-btn-container p {
  margin: 14px 0 0 0;
  text-align: right;
  color: #fff;
}

.login-error-msg {
  color: red;
  font-size: 16px;
}

.login-error-msg-high {
  text-align: center;
  padding-bottom: 3%;
  color: red;
  font-size: 20px;
}

.logo-img {
  margin-left: 115px;
  margin-bottom: 15px;
}

.logo-img > img:nth-child(1) {
  width: 300px;
  height: 80px;
  margin-left: -70px;
}

/* <span style="color: #4286f4">uva</span> */
/* .login-title */

