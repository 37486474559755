@import '~antd/dist/antd.css';
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

* {
  font-family: 'Poppins', sans-serif;
}

.page-wrapper {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  flex-direction: column;
  width: 90%;
  font-weight: 500;
  margin: 20px auto;
  min-width: 0;
}

.content-wrapper {
  width: 100%;
  font-weight: 500;
  margin: 20px auto;
  min-width: 0;
}

.filters-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  min-width: 0;
  gap: 8px;
  width: 100%;
}

.button-group {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 25px;
  margin-bottom: 25px !important;
}

.atualizar {
  align-self: flex-end;
}

.download {
  align-self: flex-start;
}

/* #components-layout-demo-top-side-2 .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}

.ant-row-rtl #components-layout-demo-top-side-2 .logo {
  float: right;
  margin: 16px 0 16px 24px;
} */
