.MenuItem {
  text-decoration: none;
  color: black;
  border-color: white;
  background-color: white;
}

.ant-menu-item-active {
  background-color: white !important;
}
.ant-menu-item-selected {
  color: black;
  background-color: white !important;
}
