.login {
  width: 100%;
  min-height: 100vh;
  padding: 0 20px;
  background: #e2e2e2;
  display: flex;
}

.login-container {
  padding: 60px;
  margin: auto;
  width: 100%;
  max-width: 520px;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
}

/* background: radial-gradient(
  ellipse at left bottom,
  rgba(9, 60, 105, 1) 0%,
  rgba(25, 56, 130, 0.9) 59%,
  rgba(20, 63, 133, 0.9) 100%
); COR ANTERIOR DO LOGIN-CONTAINER
box-shadow: 0 50px 70px -20px rgba(0, 0, 0, 0.8); 
*/

.login-btn {
  border: none;
  outline: none;
  width: 100%;
  padding: 15px 0;
  color: #fff;
  font-size: 16px;
  letter-spacing: 1px;
  background: #0e6ab9;
  cursor: pointer;
  transition: all 0.2s;
}

.login-btn:hover {
  color: #2e2e2e;
  background: #fcca37;
}

.login-title {
  text-align: center;
  padding-bottom: 10%;
  color: white;
  line-height: 1;
  font-size: 30px;
}

.login-label {
  color: rgb(0, 0, 0);
  margin: 14px 0;
  display: block;
  font-size: 22px;
  line-height: 1;
}

.login-input {
  width: 100%;
  border: none;
  outline: none;
  font-size: 19px;
  padding: 10px;
  background: rgba(101, 158, 224, 0.1);
  color: rgb(0, 0, 0);
  letter-spacing: 1px;
}

.login-btn-container {
  width: 100%;
  padding: 24px 0;
}

.login-btn-container p {
  margin: 14px 0 0 0;
  text-align: right;
  color: #fff;
}

.login-error-msg {
  color: red;
  font-size: 16px;
}

.login-error-msg-high {
  text-align: center;
  padding-bottom: 3%;
  color: red;
  font-size: 20px;
}

.logo-img {
  margin-left: 115px;
  margin-bottom: 15px;
}

.logo-img > img:nth-child(1) {
  width: 300px;
  height: 80px;
  margin-left: -70px;
}

/* <span style="color: #4286f4">uva</span> */
/* .login-title */
