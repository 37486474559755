h6 {
  font-weight: 600;
  margin-top: 4px;
  font-size: 20px;
}

.ant-descriptions {
  min-width: 1000px;
}

.ant-table-thead {
  font-weight: 700 !important;
}

.colunas {
  font-weight: 600;
}

.ant-btn {
  margin-left: 0px;
}

.anticon-sync > svg:nth-child(1) {
  margin-bottom: 6px;
}

.alert-billing {
  margin-top: 20px;
}